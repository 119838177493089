import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { RestaurantService } from "../../service/restaurantService/restaurantService";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";
import DEV from "../../url";
const Restaurant = () => {
    //Restaurant JS Section

    let emptyRestaurant = {
        id: 0,
        title: "",
        description: "",
        image: "",
        rate: 0,
        type: "",
        price: "",
    };
    const restaurantService = new RestaurantService();
    const [restaurant, setRestaurant] = useState(null);
    const [restaurantDialog, setRestaurantDialog] = useState(false);
    const [submittedRestaurant, setSubmittedRestaurant] = useState(false);
    const [restaurants, setRestaurants] = useState([]);
    const [selectedRestaurants, setSelectedRestaurants] = useState(null);
    const [deleteRestaurantDialog, setDeleteRestaurantDialog] = useState(false);
    const [deleteRestaurantsDialog, setDeleteRestaurantsDialog] = useState(false);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [img, setImg] = useState("");
    const [rate, setRate] = useState(0);
    const [type, setType] = useState("");
    const [price, setPrice] = useState("");

    const dropdownValues = [
        { name: "Petit déjeuner", code: "petit_dej" },
        { name: "Déjeuner", code: "dej" },
        { name: "Tisanerie", code: "ts" },
    ];

    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [convertedContent, setConvertedContent] = useState(null);

    const handleEditorChange = (state) => {
        setEditorState(state);
        convertContentToHTML();
    };

    const convertContentToHTML = () => {
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(currentContentAsHTML);
    };
    const createMarkup = (html) => {
        return {
            __html: DOMPurify.sanitize(html),
        };
    };
    const displayRestau = async() => {
        toast.current.show({ severity: "success", summary: "Successful", detail: "The data are successfully loaded", life: 3000 });
        return await restaurantService.getRestaurants().then((res) => setRestaurants(res));
    };
    useEffect(() => {
       
        displayRestau();
    }, []);

    const hideDeleteRestaurantDialog = () => {
        setDeleteRestaurantDialog(false);
    };

    const deleteRestaurant = (e, restaurant) => {
        e.preventDefault();
        setRestaurant({ ...restaurant });
        setDeleteRestaurantDialog(true);
    };

    const confirmDeleteRestaurant = async (e, id) => {
        e.preventDefault();
        await restaurantService.deleteRestaurant(id).then(
            (res) => {
                toast.current.show({ severity: "success", summary: "Successful", detail: "Data Removed", life: 3000 });
                setDeleteRestaurantDialog(false);
                const trash = restaurants.filter(() => res?.result !== id);
                setRestaurants(trash);
                window.location.reload();
            },
            [restaurants]
        );
    };

    const openNewRestaurant = () => {
        setRestaurant(emptyRestaurant);
        setSubmittedRestaurant(false);
        setRestaurantDialog(true);
    };

    const saveRestaurant = () => {
        setSubmitted(true);
        const formData = new FormData();
        formData.append("title", title);
        formData.append("description", convertedContent);
        formData.append("img", img);
        formData.append("rate", rate);
        formData.append("type", type);
        formData.append("price", price);

        if (type === "" || img === "" || type === "") {
            toast.current.show({ severity: "error", summary: "Merci de bien compléter les champs", life: 3000 });
            setRestaurantDialog(true);
        } else {
            restaurantService.createRestaurant(formData).then(() => {
                toast.current.show({ severity: "success", summary: "Successful", detail: "Menu Créée avec Succées", life: 3000 });
                // alert(JSON.parse(formData));
                
            });

            // window.location.reload(false);
            // displayRestau();
            window.location.reload();
            setRestaurantDialog(false);
        }
    };

    const hideDialogRestaurant = () => {
        setSubmittedRestaurant(false);
        setRestaurantDialog(false);
    };

    const handleUploadChange = (e) => {
        if (e.target.files[0]) {
            setImg(e.target.files[0]);
        }
    };
    //END

    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Nouvelle formule" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNewRestaurant} />
                </div>
            </React.Fragment>
        );
    };

    //Restaurant Section
    const titleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Titre</span>
                {rowData.title}
            </>
        );
    };

    const imgBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Image</span>
                <img src={`${DEV}uploads/restaurants/${rowData?.img}`} height={120} width={150} alt={""} />
            </>
        );
    };

    const rateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Score</span>
                {rowData.rate}
            </>
        );
    };

    const typeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Type</span>
                {rowData.type}
            </>
        );
    };

    const priceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Prix</span>
                {rowData.price}
                {"DT / Personne"}
            </>
        );
    };

    // END
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <a href={`/#/menus/modifier/` + rowData.id}>
                    <Button title="Modifier ce menu" icon="pi pi-pencil" className="p-button-rounded p-button-success mr-1"></Button>{" "}
                </a>

                <a href={`/#/menus/modifierImage/` + rowData.id}>
                    <Button title="Modifier cet image" icon="pi pi-upload" className="p-button-rounded p-button-warning mr-1"></Button>{" "}
                </a>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={(e) => deleteRestaurant(e, rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Nos goûts de luxe</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Cherchez ..." />
            </span>
        </div>
    );

    const restaurantDialogFooter = (
        <>
            <Button label="Annuler" icon="pi pi-times" className="p-button-text" onClick={hideDialogRestaurant} />
            <Button label="Sauvegarder" icon="pi pi-check" className="p-button-text" onClick={saveRestaurant} />
        </>
    );

    const deleteRestaurantDialogFooter_ = (rowData) => (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteRestaurantDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={(e) => confirmDeleteRestaurant(e, rowData.id)} />
        </>
    );

    return (
        <div className="grid crud-demo " style={{ marginTop: 0 }}>
            <div className="col-12">
                <div className="card">
                    <Toast style={{ marginTop: 0 }} ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={restaurants}
                        selection={selectedRestaurants}
                        onSelectionChange={(e) => setSelectedRestaurants(e.value)}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Montrant {first} à {last} de {totalRecords} éléments"
                        globalFilter={globalFilter}
                        emptyMessage="Aucun Menu n'a été trouvée."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
                        <Column field="title" header="Titre" sortable body={titleBodyTemplate} headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>
                        <Column header="Image" body={imgBodyTemplate} headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>
                        <Column header="Prix" body={priceBodyTemplate} headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>
                        <Column header="Score" body={rateBodyTemplate} headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>
                        <Column field="type" header="Type" body={typeBodyTemplate} sortable headerStyle={{ width: "20%", minWidth: "8rem" }}></Column>
                        <Column headerStyle={{ width: "20%", minWidth: "10rem" }} body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={restaurantDialog} style={{ width: "450px" }} header="Créer un Menu" modal className="p-fluid" footer={restaurantDialogFooter} onHide={hideDialogRestaurant}>
                        <div className="field">
                            <label htmlFor="title">Titre</label>
                            <InputText id="title" value={title} onChange={(e) => setTitle(e.target.value)} required autoFocus className={classNames({ "p-invalid": submitted && !title })} />
                            {submitted && !title && <small className="p-invalid">Champ Obligatoire.</small>}
                        </div>

                        <div className="field">
                            <label htmlFor="description">Description</label>
                            <Editor editorState={editorState} onEditorStateChange={handleEditorChange} wrapperClassName="wrapper-class" editorClassName="editor-class" toolbarClassName="toolbar-class" />
                            <hr />
                        </div>
                        <div className="field">
                            <label htmlFor="title">Price</label>
                            <InputText id="title" value={price} onChange={(e) => setPrice(e.target.value)} />
                        </div>
                        <div className="field">
                            <label htmlFor="image">Image</label>
                            <InputText type="file" className={classNames({ "p-invalid": submitted && !img })} id="img" name="img" onChange={handleUploadChange} required />
                            {submitted && !img && <small className="p-invalid">Champ Obligatoire.</small>}

                            <div className="mt-3" style={{ borderRadius: 5, height: "auto", border: "1px solid green", backgroundColor: "#B7E5B0", color: "black" }}>
                                <p className="pt-2 mx-3">
                                    <i className="pi pi-fw pi-file"></i> <b>A noter :</b> <br />
                                    - L'image est un champ obligatoire. <br />- Le nom de l'image doit être sous format <b>jpeg, png ou bien jpg</b>. <br />- Le nom de l'image ne doit pas contenir des éspaces.
                                </p>
                            </div>

                            <div align="center">
                                <img src={img} alt="imageRestaurant" className="w-25" />
                            </div>
                        </div>

                        <div className="field">
                            <label htmlFor="title">Note</label>
                            <InputText type={"number"} id="rate" value={rate} onChange={(e) => setRate(e.target.value)} required autoFocus className={classNames({ "p-invalid": submittedRestaurant && !rate })} />
                            {submittedRestaurant && !price && <small className="p-invalid">Champ Obligatoire.</small>}
                        </div>

                        <div className="field">
                            <label className="mb-3">Type</label>

                            <Dropdown 
                            className={classNames({ "p-invalid": submitted && !type })}
                            name="type" value={type} onChange={(e) => setType(e.target.value)} options={dropdownValues} optionValue="name" optionLabel="name" placeholder="Selectionner Votre Type" />
                            {submitted && !type && <small className="p-invalid">Champ Obligatoire.</small>}

                        </div>
                    </Dialog>

                    <Dialog visible={deleteRestaurantDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteRestaurantDialogFooter_(restaurant)} onHide={hideDeleteRestaurantDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {restaurant && <span>Êtes-vous sûr de vouloir supprimer cet élément : {restaurant?.title}</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteRestaurantsDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteRestaurantDialogFooter_} onHide={hideDeleteRestaurantDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {restaurant && <span>Are you sure you want to delete the selected restaurants?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps?.location?.pathname === nextProps?.location?.pathname;
};

export default React.memo(Restaurant, comparisonFn);
